<template>
  <b-container class="dashboad bg-light page-wrapper">
    <b-row class="main-content-wrapper" no-gutters>
      <b-col cols="12">
        <h1>Mobile Dropdown</h1>
      </b-col>
      <b-col cols="12">
          <template v-if="isMobile">
            <h4 class="mt-2">Mobile: Single level</h4>
            <b-form-select v-model="sampleSelection">
                <b-form-select-option-group v-for="(item, keys) in optionGroup" :label="$t('common.' + item.type) + ' / ' + item.title" v-bind:key="'group_' + keys">
                    <b-form-select-option v-for="(Accitem, Acckeys) in item.accounts" :value="Accitem" v-bind:key="'group_' + Acckeys">{{ '- ' + Accitem.title }}</b-form-select-option>
                </b-form-select-option-group>
            </b-form-select>
            <h4 class="mt-2">Mobile: Mulit level</h4>
            <b-form-select v-model="sampleSelection">
                <template v-for="(item, keys) in multilevel" :label="item.title">
                    <b-form-select-option disabled :value="item" v-bind:key="'groupMulti_' + keys">{{ item.title }}</b-form-select-option>
                    <b-form-select-option-group v-for="(Grpitem, Grpkeys) in item.groups" :label="'- ' + Grpitem.title" v-bind:key="'groupMultiGrp_' + Grpkeys + keys">
                      <b-form-select-option v-for="(Accitem, Acckeys) in Grpitem.accounts" :value="Accitem" v-bind:key="'groupIte_' + Acckeys">{{ '- ' + Accitem.title }}</b-form-select-option>
                    </b-form-select-option-group>
                </template>
            </b-form-select>
          </template>
          <template v-else>
            <h4>Desktop</h4>
            <custom-select
              v-model="sampleSelection"
              :options="['Sample1', 'sample2']"
            >
            </custom-select>
          </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import mobileDetectMixins from '@/mixins/mobileDetect'
import CustomSelect from '@/components/common/CustomSelect'
import axios from 'axios'

export default {
  name: 'MobileDropdown',
  mixins: [mobileDetectMixins],
  components: { CustomSelect },
  data () {
    return {
      sampleData: [],
      sampleSelection: null,
      loading: false
    }
  },
  created () {
    this.loadAccounts('income,asset')
      .then(data => {
        this.sampleData = data
      })
  },
  methods: {
    applyfilter (value) {
      // console.lo('value:', value)
    },
    loadAccounts (type) {
      this.loading = true
      return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups?account_type=${type}&sort[0][key]=type&sort[0][direction]=desc&sort[1][key]=title&sort[1][direction]=asc&log=MobileDropdown`)
        .then(response => response.data.data)
        .then(response => {
          return response
        })
        .catch(error => {
          console.error(error)
          return []
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    optionGroup () {
      const optionArray = []
      if (this.sampleData.length > 0) {
        const group = this.sampleData.map(items => {
          return Object.assign(items, { title: items.title, accounts: items.accounts })
        })
        const income = group.filter(item => item.type === 'income')
        const asset = group.filter(item => item.type === 'asset')
        return [...income, ...asset]
      }
      return optionArray
    },
    multilevel () {
      const optionArray = []
      if (this.sampleData.length > 0) {
        const group = this.sampleData
        const income = group.filter(item => item.type === 'income')
        const asset = group.filter(item => item.type === 'asset')
        const groups = [
          {
            title: this.$t('common.income'),
            groups: income
          },
          {
            title: this.$t('common.expense'),
            groups: asset
          }
        ]
        return groups
      }
      return optionArray
    }
  }
}
</script>

<style lang="scss">
</style>
